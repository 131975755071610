import useJwt from '@/auth/jwt/useJwt';
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig';
import sonaxConfig from '@/config';
import {setPushToken} from '@/api/sonax-user/push/push';

export default {
  data() {
    return {

    }
  },
  filters: {
    
  },
  computed: {
    isAppDevice(){
      return this.$store.state.erns.deviceType == "APP";
    },
    deviceType(){
      return this.$store.state.erns.deviceType;
    },
    deviceTypeNative(){
      return this.$store.state.erns.deviceTypeNative;
    },
    isAppAndroid(){
      return this.$store.state.erns.deviceTypeNative == "ANDROID";
    },
    isAppIos(){
      return this.$store.state.erns.deviceTypeNative == "IOS";
    },
    isMain(){
      return this.$route.name == "sonax-user-main"
    },
  },
  methods: {  
    ermOpenWindow(url){
      const _vm = this;      
      
      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.openBrowser){        
        window.sonax_app.openBrowser(url);
        return;
      }
      
      window.open(url, '_blank');
    },

    ermStackPage(routerData, modalCloseCallback, message){
      const _vm = this;      
      //console.debug("$this.$router");
      //console.debug(_vm.$router);

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.stackPage){
        window.modalCloseCallback = modalCloseCallback;

        const esc = encodeURIComponent;
        const params = routerData.query;
        let query = "";
        if(params){
          query = "?"+Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&');        
        }
        const url = `${routerData.path}${query}`;
        //console.debug(url);
        window.sonax_app.stackPage(url, message);
        return;
      }
      
      _vm.$router.push(routerData).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.error(err);
        }
      });
    },

    ermPushPage(routerData){
      const _vm = this;      
      // //console.debug("$this.$router");
      // //console.debug(_vm.$router);
      // if(_vm.isAppDevice && window.sonax_app && window.sonax_app.stackPage){
      //   window.sonax_app.stackPage(routerData.path);
      //   return;
    // }
      
      _vm.$router.push(routerData).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (!(
          (err.name == 'NavigationDuplicated' &&
            err.message.includes('Avoided redundant navigation to current location'))
          || err.message.includes('Redirected when going from')
        )) {
          console.error(err);
        }
      });
    },

    ermReplacePage(routerData){
      const _vm = this;      
      // //console.debug("$this.$router");
      // //console.debug(_vm.$router);
      // if(_vm.isAppDevice && window.sonax_app && window.sonax_app.stackPage){
      //   window.sonax_app.stackPage(routerData.path);
      //   return;
      // }
      
      _vm.$router.replace(routerData).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (!(
          (err.name == 'NavigationDuplicated' &&
            err.message.includes('Avoided redundant navigation to current location'))
          || err.message.includes('Redirected when going from')
        )) {
          console.error(err);
        }
      });
    },

    //환경설정 오픈
    ermSettingPage(){
      const _vm = this;
      //console.debug("$this.$router");
      //console.debug(_vm.$router);
      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.showSetting){
        const data = {
          tokenType : jwtDefaultConfig.tokenType,
          accessToken : useJwt.getToken()
        }
        
        window.sonax_app.showSetting(JSON.stringify(data));
        return;
      }
      
      _vm.ermStackPage({path:'/sonax/mypage/setting'}, (message)=>{
        if(_vm.isAppDevice && window.sonax_app){
          _vm.fetchData();
          return;
        }
      }, "");
    },

    //Push token 가져오기
    ermGetPushToken(){
      const _vm = this;

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.getPushToken){
        const pushToken = window.sonax_app.getPushToken();
        console.debug(`pushToken:${pushToken}`);
        return pushToken;
      }

      return "";
    },

    //Push token 전송
    async ernSendPushToken(){
      const _vm = this;

      if(!(_vm.isAppDevice && window.sonax_app && window.sonax_app.getPushToken)){
        return;
      }
      
      try{
        const pushTokenDataJson = localStorage.getItem(sonaxConfig.storagePushTokenKey);
        let pushTokenInfo = _vm.$store.state.erns.pushTokenInfo;
        if(pushTokenDataJson){
          const sPushToken = JSON.parse(pushTokenDataJson);
          pushTokenInfo = {...sPushToken};        
        }

        const curPushToken = _vm.ermGetPushToken();
        pushTokenInfo.token = curPushToken;
        _vm.$store.dispatch('erns/setPushTokenInfo', pushTokenInfo);
        
        const userInfo = _vm.$store.state.erns.userInfo;
        pushTokenInfo = _vm.$store.state.erns.pushTokenInfo;
        console.debug('pushTokenInfo:');
        console.debug(JSON.stringify(pushTokenInfo));

        if(userInfo 
          && userInfo.userSeq && userInfo.userSeq > 0
          && pushTokenInfo
          && pushTokenInfo.token && pushTokenInfo.token != ""
          //&& pushTokenInfo.transToken != pushTokenInfo.token
        ){
          const sendToken = pushTokenInfo.token;
          console.debug("send Token : "+ sendToken);

          //send token
          const pushParam = {
            userSeq : userInfo.userSeq,
            appId : sendToken,
            devType : _vm.$store.state.erns.deviceTypeNative
          }
          const res = await setPushToken(pushParam);
          if(res.result == "S"){
            pushTokenInfo.transToken = sendToken;
            pushTokenInfo.transDate = (new Date()).getTime();

            _vm.$store.dispatch('erns/setPushTokenInfo', pushTokenInfo);
          }
        }else{
          console.debug("don't send Token : "+ pushTokenInfo.token);
        }
      }catch(err){
        console.debug(err);
      }
    },

    hasHistory () { return window.history.length > 2 },
    ermHistoryBack(){
      const _vm = this; 

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.historyBack){        
        window.sonax_app.historyBack();
        return;
      }

      if(_vm.hasHistory()){
        _vm.$router.go(-1);
      }else{
        _vm.ermReplacePage({name:'sonax-user-main'});
      }
    },

    //클립보드 복사
    async edmSetClipboard(data, message){
      const _vm = this;

      try{
        await _vm.$copyText(data);

      }catch(e){
        console.log(e);
        return;
      }
      
      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.showToast){        
        var msg = message || '클립보드에 복사 되었습니다.';
        window.sonax_app.showToast(msg);
        return;
      }
    },

    //전화 다이얼
    async edmCallDial(tel){
      const _vm = this;

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.callDial){        
        window.sonax_app.callDial(tel);
        return;
      }
    },

    //애플로그인
    edmAppleLogin(){
      const _vm = this;

      if(_vm.isAppDevice && window.sonax_app && window.sonax_app.appleLogin){        
        window.sonax_app.appleLogin();
        return true;
      }

      return false;
    },

    //IOS javascript interface 등록
    edmRegisterIsoJavascriptInterface(){
      const _vm = this;
      
      window.sonax_app ={
        // historyBack : function(message){
        //   window.webkit.messageHandlers.back.postMessage(true);
        // },
        openBrowser : function(link){
          console.debug(link);
          window.webkit.messageHandlers.outLink.postMessage(link);
        },
        showToast : function(message){
          console.debug(message);
          window.webkit.messageHandlers.showToast.postMessage(message);
        },                
        getPushToken : function(){
          //const ret = window.webkit.messageHandlers.getPushToken.postMessage();
          const iosPushToken = _vm.$cookie.get("sonaxService.app.device.pushToken") || "";
          console.debug(`getPushToken:${iosPushToken}`);
          return iosPushToken;
        },
        appleLogin : function(){
          console.info("appleLogin");
          window.webkit.messageHandlers.appleLogin.postMessage("test");
        }
      }
    }
  }
}

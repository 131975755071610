import device from './device';

import datetimeFilter from './datetimeFilter';
import numberFilter from './numberFilter';
import pagination from './pagination';
import formatter from './formatter';
import loading from './loading';
import routerHistory from './routerHistory';
import uiScript from './uiScript';
import mapUtils from './mapUtils';
import ernsLoadImage from './ernsLoadImage';

import {getHomeRouteForLoggedInUser } from '@/auth/utils';
import useJwt from '@/auth/jwt/useJwt';
import {getUncheckNotiCount} from '@/api/sonax-user/comm/push'
import {getCalendar} from '@/api/sonax-user/reservation/reservation';

const base = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [
    device,
    routerHistory, 
    loading, 
    datetimeFilter,
    numberFilter, 
    pagination, 
    formatter, 
    uiScript,
    mapUtils,
    ernsLoadImage
  ],
  data() {
    return {
      
    }
  },
  computed: {
    isReviewShow() {
      return true;
    },
    eumConstAdress() {
      return process.env.VUE_APP_CONST_ADDRESS;
    },
    eumConstCustomerCenter() {
      return process.env.VUE_APP_CONST_CUSTOMER_CENTER;
    },
    eumConstBusinessNum() {
      return process.env.VUE_APP_CONST_BUSINESS_NUM;
    },
    chosen_browser_is_supported() {
      if ("Microsoft Internet Explorer" === window.navigator.appName) {
        return document.documentMode >= 8;
      }

      console.debug(`window.navigator.userAgent:${window.navigator.userAgent}`);

      if (/iP(od|hone)/i.test(window.navigator.userAgent)) {
        return false;
      }

      if (/Android.*Mobile/i.test(window.navigator.userAgent)) {
        return false;
      }
      
      if (/iP(od|hone)/i.test(window.navigator.userAgent) || /IEMobile/i.test(window.navigator.userAgent) || /Windows Phone/i.test(window.navigator.userAgent) || /BlackBerry/i.test(window.navigator.userAgent) || /BB10/i.test(window.navigator.userAgent) || /Android.*Mobile/i.test(window.navigator.userAgent)) {
        return false;
      }
      return true;
    }
  },
  /*
  beforeRouteLeave : function(to, from, next){
    //페이지 이동전 검색 param 저장용
    try{
      const routerName = from.name;
      if(from.matched.length > 0){
        const coms = from.matched[0].components.default;
        const methods = coms.methods;
        if(methods && methods.hasOwnProperty('onBeforeRouter')){
          const fncOnBeforeRouter = methods['onBeforeRouter'];
          fncOnBeforeRouter(routerName);
        }
      }
    }catch(err){
      console.error(err);
    }
  
    return next();
  },
  */
  filters :{
    //사용여부 필터
    useYnName(val){
      return val =="Y" ? "사용" : val =="N" ? "미사용" : "-";
    },

    //사용자 아이디 표시
    eufUserId(val){
      let ret = val || "";
      
      if(ret.indexOf("NAVER-") == 0){
        return "NAVER";
      }

      if(ret.indexOf("FACEBOOK-") == 0){
        return "FACEBOOK";
      }

      if(ret.indexOf("APPLE-") == 0){
        return "APPLE";
      }

      if(ret.indexOf("KAKAO-") == 0){
        return "KAKAO";
      }

      return ret;
    },

    //공통코드에서 코드에 해당하는 코드명 출력
    eufGetCodeName(val, codeList){
      let ret = "-";

      if(!codeList || codeList.length < 1 || !val ){return ret;}

      const cd = codeList.find((item)=>{
        return item.value == val;
      });

      if(cd){
        return cd.label;
      }

      return "-";
    },   
    
    //서비스 명
    eufServiceName(serviceType) {
      if(!serviceType){
        return "";
      }

      const serviceTypeSplit = serviceType.split(",");

      let strArr = [];

      for(let mData of serviceTypeSplit){
        if(mData == "SVC_001"){
          strArr.push("세차");
        }else if(mData == "SVC_002"){
          strArr.push("코팅");
        }else if(mData == "SVC_003"){
          strArr.push("광택");
        }
      }

      return strArr.join("+");
    },

    //영업시간 포맷터
    eufOpenTimeFormatter(openModel) {
      if(openModel.closeYn == null){
        return;
      }

      if(openModel.closeYn == "Y"){
        return "휴무";
      }

      return openModel.startTime.substring(0,5) + " ~ " + openModel.endTime.substring(0,5);
    },

    //입고 이후 서비스타입 -> 서비스요약 
    eufServiceTypeOrName(rsData) {
      if(!rsData) {
        return "";
      }
      
      // 입고 이후 서비스 목록 생성
      const serviceLength = rsData.serviceSummeryList.length;

      if(serviceLength > 0) {
        if(serviceLength.length == 1) {
          return rsData.serviceSummeryList[0].serviceName;
        } else {
          return rsData.serviceSummeryList[0].serviceName + " 외 " + (serviceLength - 1) + "건";
        }
      }
      
      // 서비스 목록이 없을 경우
      const serviceTypeSplit = rsData.serviceType.split(",");
      let strArr = [];

      for(let mData of serviceTypeSplit){
        if(mData == "SVC_001"){
          strArr.push("세차");
        }else if(mData == "SVC_002"){
          strArr.push("코팅");
        }else if(mData == "SVC_003"){
          strArr.push("광택");
        }
      }

      return strArr.join("+");
    },

    eufPointType(pointType) {
      if(!pointType || pointType.length <= 0) return "";

      switch(pointType) {
        case "PNT_TYPE_0001":
          return "소낙스포인트";
        case "PNT_TYPE_0002":
          return "블루포인트";
      }
    } 
  },
  methods: {
    //Logout
    async eumLogout(){
      const _vm = this;
      //TODO : 서버로 토큰 반환 구현 필요

      //Client 로그아웃 처리
      _vm.$store.dispatch('erns/setUserInfo', null);  //회원정보
      _vm.$store.dispatch('erns/setReservationData', null); //예약정보
      _vm.$store.dispatch('erns/setJoinData', null); //회원가입정보
      await useJwt.logout();
      
      //console.debug(_vm.$route);
      const route = _vm.$route;
      if("sonax-user-main" == route.name){
        _vm.$router.go(0);
      }else{
        _vm.ermPushPage({path:getHomeRouteForLoggedInUser()});
      }
    },

    //Device Type 설정
    eumSetDeviceType(deviceType){
      const _vm = this;
      const dt = deviceType || "WEB";

      _vm.$store.dispatch('erns/changeOption', {
        key : "deviceType", value : dt.toUpperCase()
      }); 
    },

    //Device Type 설정
    eumSetDeviceTypeNative(deviceTypeNative){
      const _vm = this;
      const dt = deviceTypeNative || "android";

      _vm.$store.dispatch('erns/changeOption', {
        key : "deviceTypeNative", value : dt.toUpperCase()
      }); 
    },

    //공통코드 목록 => [{label:'text', value:'data'}] 로 변경
    eumConvertToVueSelectOption(rawCommonCodes){
      return rawCommonCodes.map(item=>{
        return {label:item.codeNm, value:item.code};
      });
    },
    
    //Vue-Select 등에서 선택된 값의 value 값을 String 으로 리턴
    eumGetVSelectValue(selectModel, propName) {
      const pName = propName || 'value';
      if (!selectModel) { return null; }

      if (typeof selectModel === 'object') {
        if (selectModel.hasOwnProperty(pName)) {
          return selectModel[pName];
        }
      }
      return null;
    },

    //공통코드 List에서 코드를 찾아 Object리턴
    eumGetCodeObject(codeVal, codes){
      if(codeVal && codes && codes.length > 0){
        return codes.find(item=>{
          return item.value == codeVal;
        });
      }

      return null;
    },

    //오류 alert
    async alertError(errorMsg, title, timer){
      const _vm = this;

      let eMsg = '알 수 없는 오류가 발생하였습니다.';
      if(errorMsg instanceof Error){
        const eRes = errorMsg.response;
        if(eRes && eRes.data && eRes.data.returnMessage){
          eMsg = eRes.data.returnMessage;
        }else{
          eMsg = errorMsg.message;
        } 
      }else{
        eMsg = errorMsg || eMsg;
      }
      
      return await _vm.$swal({
        icon: 'error',
        title : title,
        text: eMsg,         
        timer: timer,
        customClass: {
          confirmButton: 'btn btn-primary btn-lg',
        },
        buttonsStyling: false,
        confirmButtonText: "확인"
      });
    },

    async alertErrorHtml(errorMsg, title, timer){
      const _vm = this;
 
      let eMsg = '알 수 없는 오류가 발생하였습니다.';
      if(errorMsg instanceof Error){
        const eRes = errorMsg.response;
        if(eRes && eRes.data && eRes.data.returnMessage){
          eMsg = eRes.data.returnMessage;
        }else{
          eMsg = errorMsg.message;
        } 
      }else{
        eMsg = errorMsg || eMsg;
      }

      return await _vm.$swal({
        icon: 'error',
        title : title,
        html: eMsg,         
        timer: timer,
        customClass: {
          confirmButton: 'btn btn-primary btn-lg',
        },
        buttonsStyling: false,
        confirmButtonText: "확인"
      });
    },

    //성공 alert (자동 꺼짐)
    async alertSuccess(message, title, timer){
      const _vm = this;
      return await _vm.$swal({
        icon: 'success',
        title: title,
        text: message, 
        showConfirmButton: false,
        timer: timer || 1500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });
    },

    eumCamel2under(str){
      if(!str){
        return str;
      }

      return str.replace(/([A-Z])/g, function(arg){
        return "_"+arg.toLowerCase();
      }).toUpperCase();
    },

    eumUnder2camel(str){
      if(!str){
        return str;
      }
      
      return str.toLowerCase().replace(/(\_[a-z])/g, function(arg){
        return arg.toUpperCase().replace('_','');
      });
    },

    //파일 다운로드
    eumFileDownPath(fileId){
      const link = `${base}/file/download.do?fId=${fileId}`;
      return link;
    },

    //첨부파일 이미지 로드
    eumFileImagePath(fileId, pThumb, replaceImage){
      if(!fileId && replaceImage){
        return replaceImage;
      }
      
      const thumb = pThumb || "1";
      const link = `${base}/file/image.do?thumb=${thumb}&fId=${fileId}`;
      return link;
    },

    //첨부파일 이미지 로드
    eumFileImagePathWithSize(fileId, pThumb, replaceImage, size){
      if(!fileId && replaceImage){
        return replaceImage;
      }
      
      const thumb = pThumb || "1";
      let link = `${base}/file/image.do?thumb=${thumb}&fId=${fileId}`;
      if(thumb == "1" && size){
        link += "&size="+size;
      }
      return link;
    },

    //서버 정적 리소스
    eumRefPath(refPath){      
      const link = `${base}${refPath}`;
      return link;
    },

    //예약 파라미터 가져오기
    eumGetReservationParam(reservationData){

      const rData = reservationData;

      console.log(rData)
      const retParam = {
        serviceType :  rData.serviceType,
        reservationDate : rData.rTime.date + " " + rData.rTime.time,

        memYn : "Y",
        clientName : rData.visitUser.userName,
        clientContact : rData.visitUser.phone,

        franchiseId : rData.store.frSeq,

        vehicleCd : rData.vehicle.vehicleCd,
        vehicleType : rData.vehicle.vehicleType,
        vehicleNumber : rData.vehicle.vehicleNumber,

        userVoucherId : rData.voucher.userVoucherId,

        request : rData.memo,

        termChecks : rData.termsChecks,
        couponList: rData.coupon,

        reservationJson : JSON.stringify(rData),

        rcpType : rData.rcpType,
        rcpCode : rData.rcpCode,
        rcpNum : rData.rcpNum,

        usePointList: rData.usePointList
      };

      return retParam;
    },

    /** 알림 미확인 수 뱃지 표시 */
    async setNotiUnCheckBadge(){ 
      const _vm = this;
      const params = {};
      let resCount = 0;

      const userInfo = _vm.$store.state.erns.userInfo;
      if(userInfo && userInfo.userSeq > 0){
        const res = await getUncheckNotiCount(params);
        resCount = res.objReturnData;            

        if(!resCount) {
          resCount = 0;
        }
      }
      
      _vm.$store.dispatch('erns/setNoCheckNotiCount', resCount);            
    },

    /** 전화번호 존재유무/최소자릿수 확인 */
    eumTelValidation(tel) {
      const _vm = this;
      if(!tel) {
        return false;
      }
      const telLength = tel.replace(/-/g, '').length;
      
      if(telLength >= 9) {
        return true;
      }
      return false;
    },

    eumLocationUrl(refUrl) {
      const _vm = this;

      if(!refUrl) {
        return refUrl;
      }
      
      // 외부
      if(refUrl.indexOf("http:") >= 0 || refUrl.indexOf("https:") >= 0){
        window.open(refUrl, '_blank');
        return;
      }

      // 내부
      const routerData = _vm.getUrlRouterData(refUrl);
      _vm.ermPushPage(routerData);
    },

    getUrlRouterData(search) {
      const hashes = search.slice(search.indexOf('?') + 1).split('&')
      const params = {}
      hashes.map(hash => {
          const [key, val] = hash.split('=')
          params[key] = decodeURIComponent(val)
      })
      const path = search.split('?')[0];
      
      const routerData = {
        path: path,
        query: params
      };

      return routerData;
    },

    setCalendarColor(){
      const _vm = this;

      window.setCalendarColor = function(item){
        const $html = $(".calendar.open").find(".week-days").find(".day");
        $html.eq(0).css("color","#ff4040");
        $html.eq(6).css("color","#0072ff");

        const $html2 = $(".calendar.open").find(".days-row");
        $html2.each(function(index, item){
          const eq0 = $(this).find(".day").eq(0);
          const eq6 = $(this).find(".day").eq(6);
          
          if(!eq0.hasClass("outside")){
            eq0.css("color","#ff4040");
          }
          if(!eq6.hasClass("outside")){
            eq6.css("color","#0072ff");
          }
        });

        let yyyymm = "";
  
        if(item.month){
          const month = item.month+1;
          if(month < 10){
             yyyymm = item.year + "-0" + month + "-01";
          }else{
             yyyymm = item.year + "-" + month + "-01";
          }
        }else{
          const year = new Date().getFullYear();
          const month = new Date().getMonth()+1;
          if(month < 10){
             yyyymm = year + "-0" + month + "-01";
          }else{
             yyyymm = year + "-" + month + "-01";
          }
        }
        
        _vm.setData(yyyymm);
      };
    },

    async setData(yyyymm){
      const _vm = this;

      try{
        _vm.loading(true);

        const res = await getCalendar({frSeq:_vm.$store.state.erns.reservationData.store.frSeq, rsvDate:yyyymm});
        const $html = $(".calendar.open").find(".days-row").find(".day").not(".outside");

        for(let [idx,mData] of res.objReturnData.entries()){
          if(mData.isHoliYn == "Y"){
            $html.eq(idx).css("color","#ff4040");
          }
        }
      }catch(err){
        _vm.alertError(err);
      }finally{
        _vm.loading(false);
      }
    },


    async eumReLogin(){
      const _vm = this;
      //TODO : 서버로 토큰 반환 구현 필요

      //Client 로그아웃 처리
      _vm.$store.dispatch('erns/setUserInfo', null);  //회원정보
      _vm.$store.dispatch('erns/setReservationData', null); //예약정보
      _vm.$store.dispatch('erns/setJoinData', null); //회원가입정보
      await useJwt.logout();
      
      _vm.ermReplacePage({name:'sonax-user-login'});
    },
  }
}